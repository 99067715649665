
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /* direction: rtl; */
}

* {
  font-family: 'Montserrat', sans-serif;
  margin:0px;
  padding:0px;
  box-sizing: border-box;
}
.container_box {
  max-width: 1200px;
  margin: auto;
  padding: 10px;
}
.min_container{
  max-width: 600px;
  margin: auto;
  padding: 10px;
}
p{
  font-weight:400;
  font-size:12px;
}
h4{
  font-size:18px;
  font-weight:600;
}
h5{
  font-size:18px;
  font-weight:400;
}
h6{
  font-size:16px;
  font-weight:500;
}
button{
  cursor:pointer;
}
svg{
  display:block;
}
flex-3{
  flex: 3;
}
/* width scroll bar*/
::-webkit-scrollbar {
  width: 0px;
}
/* Hide arrow in number input  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}